@font-face {
  font-family: "quicksand";
  src: url("../public/fonts/Quicksand-VariableFont_wght.ttf");
}

* {
  font-family: quicksand;
  box-sizing: border-box;
}
.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: url("../public/background.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

.link {
  width: 100%;
  border-radius: 50px;
  text-decoration: none;
  background-color: black;
  color: white;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  min-height: 60px;
  max-width: 622px;
}

.link.link-header {
  border: 5px dashed black;
  background-color: yellow;
  color: black;
  min-height: 66px;
  height: 66px;
}

.link span {
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.05rem;
}

.link__logo {
  border-radius: 50%;
  margin: 0.5rem 0.5rem;
  max-width: 100%;
  width: 10%;
  height: auto;
  display: block;
}

.link:hover {
  transform: scale(1.06, 1.06);
  transition: transform 0.4s ease-in-out;
}

.logo {
  margin-top: -6rem; /* to delete */
  width: 100%;
  height: auto;
  max-width: 450px;
  max-height: 450px;
}

.logo img {
  width: 100%;
  height: auto;
}

.address {
  margin-top: -6rem; /* to delete */
  font-size: 1.3rem;
}

@media only screen and (max-width: 825px) {
  .link {
    max-width: 525px;
  }
}

@media only screen and (max-width: 525px) {
  .link {
    max-width: 100vw;
  }
}
